import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';

import noop from 'lodash/noop';
import { Style } from 'nordic/style';
import { Script } from 'nordic/script';
import { GTM } from 'nordic/gtm';
import { Hotjar } from 'nordic/hotjar';
import { MelidataTrack } from 'nordic/melidata/melidata-track';
import {
  TrackingProvider,
  CalmProvider,
} from '@pog-ui-landings-library/framework';

import { ddRumScript } from '../../utils/scripts';
import {
  DEPRECATED_SECTIONS,
  SELLERS_SECTIONS,
  SINGLE_SECTIONS,
} from '../../utils/constants';
import { THEMES } from '../../utils/themes';
import {
  hasRemoteModules,
  getSectionName,
  getUniqueSectionTypes,
} from './utils/section-helpers';
import { getTrackHandlers } from './utils/tracking-helpers';
import LandingHead from './components/LandingHead';
import SectionStyles from './components/SectionStyles';
import CalmComponent from './components/CalmComponent';
import ErrorCatchProvider from '../../components/ErrorCatchProvider';
import ModalRebrand from './components/ModalRebrand';

const View = (props) => {
  const {
    sections = [],
    experiments = {},
    mainStyles = {},
    imagesPrefix = '/',
    scope = '',
    isWebView = false,
    initCallback = noop,
    isMobile,
    landingConfig: {
      groupId,
      variantId,
      landingId,
      landingVersionId: versionId,
      variantConfigurationId,
      variantName,
      head,
      productName,
      space,
      tracking: { gtm, melidata, hotjar },
      custom: { ddrumConfig = null },
    },
    locale,
    device,
    landingStatus,
    isPreview,
    recaptchaSiteKey,
    coreValues: {
      userId,
      coupon = null,
      channel = null,
      subChannel = null,
      siteId = null,
      userAgent = null,
    },
    baseApiPath,
    csrfToken,
    isRebrand,
    theme,
    translationsRebrand,
    hiddenModal,
    keepImageURLs = false,
    clientLogsEnabled = false,
    utmParams,
    uuid,
  } = props;
  const { skipCalmTrack, calmTrack, enableShowTrack } = melidata;

  const limitedSections = new Set();

  const filteredSections = sections.filter(({ type }) => {
    const normalizedType = type.toLowerCase();

    if (
      DEPRECATED_SECTIONS.includes(normalizedType) ||
      SELLERS_SECTIONS.includes(normalizedType)
    ) {
      return false;
    }

    if (SINGLE_SECTIONS.includes(normalizedType)) {
      if (limitedSections.has(normalizedType)) {
        return false;
      }

      limitedSections.add(normalizedType);
    }

    return true;
  });

  const uniqueSectionsTypes = getUniqueSectionTypes(filteredSections);
  const hasRemoteModuleSections = hasRemoteModules(filteredSections);

  // Set handlers the components will call to report tracks
  const trackHandle = getTrackHandlers(gtm);

  const handleScroll = () => {
    const targetId = window?.location.hash.slice(1);

    if (targetId) {
      try {
        const position = document.querySelector(`#${targetId}`)?.offsetTop;

        if (position) {
          window.scrollTo(0, position);
        }
      } catch (err) {
        // Fragment and not a target section
      }
    }
  };

  useEffect(() => {
    if (document.readyState === 'complete') {
      handleScroll();
    } else {
      window.addEventListener('load', handleScroll);
    }

    // Datadog RUM script for browser, options in global settings > custom > ddrum
    if (ddrumConfig) {
      ddRumScript(ddrumConfig, {
        userId,
        coupon,
        channel,
        subChannel,
        groupId,
        variantId,
        versionId,
        variantConfigurationId,
        variantName,
        experiments,
        isWebView,
        isMobile,
        tracking: melidata,
        siteId,
        userAgent,
      });
    }

    return () => window.removeEventListener('load', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <main
      id="s-landing"
      className="s-landing s-landing--default"
      style={mainStyles}
      data-variant-name={variantName}
      data-product-name={productName}
      ref={initCallback}
    >
      <Style
        href={isMobile ? 'landingMobile.css' : 'landingDesktop.css'}
        critical
      />

      {theme === THEMES.MP && !isRebrand && (
        <Style href="rebrand-legacy.css" critical />
      )}

      {isPreview && isRebrand && <Style href="rebrand-fallback.css" critical />}

      <SectionStyles
        types={uniqueSectionsTypes}
        theme={theme}
        isMobile={isMobile}
      />

      <LandingHead head={head} scope={scope} />

      {isRebrand && (
        <ModalRebrand
          isMobile={isMobile}
          translations={translationsRebrand}
          hidden={hiddenModal}
        />
      )}

      <div className="s-sections">
        <CalmProvider context={{ isRebrand, keepImageURLs }}>
          <TrackingProvider trackHandler={trackHandle}>
            {filteredSections?.map((section) => {
              const { id, config, type, section_id: sectionId } = section;
              const { anchor, id: configId, form } = config || {};
              const key = `${type}__${id}`;

              config.tracking = {
                ...(config?.tracking || {}),
                onShow: {
                  ...(config?.tracking?.onShow || {}),
                  enabled: enableShowTrack,
                },
              };

              const sectionMetadata = {
                type,
                sectionId,
                sectionVersionId: id,
              };

              const formRequestMetadata = {
                landing_id: landingId,
                landing_group_id: groupId,
                variant_name: variantName,
                uuid,
                ...utmParams,
              };

              // FIXME: Homogenize alias in data to follow sections' schemas (They declare id as attribute).
              const sectionID = configId || anchor || type;

              const sectionName = getSectionName(type.toLowerCase());

              if (form?.recaptcha) {
                const recaptchaConfig = form.recaptcha;
                const recaptcha =
                  typeof recaptchaConfig === 'object' ? recaptchaConfig : {};

                config.form = {
                  ...(form || {}),
                  recaptcha: {
                    ...recaptcha,
                    key: recaptcha.key || recaptchaSiteKey,
                  },
                };
              }

              return (
                <CalmComponent
                  type={sectionName}
                  id={sectionID}
                  key={key}
                  config={config}
                  isMobile={isMobile}
                  isWebView={isWebView}
                  theme={
                    ['hero-section', 'navbar-section'].includes(sectionName) &&
                    isRebrand
                      ? THEMES.MP
                      : theme
                  }
                  moduleKey={key}
                  sectionMetadata={sectionMetadata}
                  formRequestMetadata={formRequestMetadata}
                />
              );
            })}
          </TrackingProvider>
        </CalmProvider>
      </div>

      {gtm && <GTM {...gtm} />}

      <MelidataTrack
        {...calmTrack}
        isDeferred={skipCalmTrack}
        MelidataTrack={experiments}
      />

      {/* Options are inferred from frontend-config.
  See https://github.com/mercadolibre/fury_frontend-metrics/tree/master/packages/hotjar#options */}
      {hotjar && !hasRemoteModuleSections && <Hotjar id={hotjar.id} />}

      <Script priority={1}>
        {`window.__PRELOADED_STATE__ = ${serialize(
          {
            experiments,
            imagesPrefix,
            isMobile,
            isWebView,
            isRebrand,
            theme,
            translationsRebrand,
            hiddenModal,
            keepImageURLs,
            baseApiPath,
            csrfToken,
            landingConfig: {
              groupId,
              variantId,
              landingId,
              versionId,
              variantConfigurationId,
              variantName,
              head,
              productName,
              space,
              tracking: {
                gtm,
                melidata,
                hotjar,
              },
              custom: { ddrumConfig },
            },
            locale,
            device,
            scope,
            sections: filteredSections,
            trackHandle,
            mainStyles,
            landingStatus,
            isPreview,
            recaptchaSiteKey,
            coreValues: {
              userId,
              coupon,
              channel,
              subChannel,
              siteId,
              userAgent,
            },
            clientLogsEnabled,
            uuid,
            utmParams,
          },
          { isJSON: true },
        )};`}
      </Script>

      <Script priority={2} src={['vendor.js']} />
    </main>
  );

  return clientLogsEnabled ? (
    <ErrorCatchProvider {...props}>{content}</ErrorCatchProvider>
  ) : (
    content
  );
};

View.propTypes = {
  experiments: PropTypes.shape({}),
  imagesPrefix: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isWebView: PropTypes.bool,
  landingConfig: PropTypes.shape({
    groupId: PropTypes.string,
    variantId: PropTypes.string,
    landingId: PropTypes.string,
    landingVersionId: PropTypes.string,
    variantConfigurationId: PropTypes.string,
    variantName: PropTypes.string,
    head: PropTypes.shape({ title: PropTypes.string }),
    productName: PropTypes.string,
    space: PropTypes.string,
    tracking: PropTypes.shape({
      gtm: PropTypes.shape({}),
      melidata: PropTypes.shape({
        calmTrack: PropTypes.shape({}),
        skipCalmTrack: PropTypes.bool,
        enableShowTrack: PropTypes.bool,
      }),
      hotjar: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    custom: PropTypes.shape({
      ddrumConfig: PropTypes.shape({}),
    }),
  }),
  locale: PropTypes.string,
  device: PropTypes.shape({
    type: PropTypes.string,
    webView: PropTypes.bool,
  }),
  mainStyles: PropTypes.shape({}),
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  scope: PropTypes.string,
  initCallback: PropTypes.func,
  csrfToken: PropTypes.string,
  baseApiPath: PropTypes.shape({}),
  landingStatus: PropTypes.shape({}),
  isPreview: PropTypes.bool,
  recaptchaSiteKey: PropTypes.string,
  coreValues: PropTypes.shape({
    userId: PropTypes.string,
    coupon: PropTypes.string,
    channel: PropTypes.string,
    subChannel: PropTypes.string,
    siteId: PropTypes.string,
    userAgent: PropTypes.string,
  }),
  isRebrand: PropTypes.bool,
  theme: PropTypes.string,
  translationsRebrand: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    confirmButton: PropTypes.string,
  }),
  hiddenModal: PropTypes.bool,
  keepImageURLs: PropTypes.bool,
  utmParams: PropTypes.shape({}),
  uuid: PropTypes.string,
  clientLogsEnabled: PropTypes.bool,
};

export default View;
